import { LitElement, html, css, unsafeCSS } from 'lit';
import loadScript from '../../../modules/load-script';

import _styles from './YandexMap.ce.scss';

export interface YandexMap {
    apiKey: string;
    centerLat: number;
    centerLng: number;
    instance: any;
    marker: string;
    zoom: number;
    currentCoords: [number, number];
    _isFetching: boolean;
    _error: Error | null;
}

export class YandexMap extends LitElement {
    constructor() {
        super();
        this.instance = null;
        this.zoom = 15;
        this._isFetching = false;
        // this.currentCoords = [55.7408847, 37.6091937];
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?lang=ru_RU`)
            .then(() => {
                const { ymaps } = window as any;
                ymaps.ready(() => {
                    this.instance = new ymaps.Map(this.renderRoot.querySelector('.map'), {
                        center: [this.centerLat, this.centerLng],
                        zoom: this.zoom,
                        controls: [],
                    });

                    this.instance.behaviors.disable('scrollZoom');
                    window.addEventListener('resize', this._onResize);

                    this._addMarker(this.centerLat, this.centerLng);
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    protected _onResize() {
        this.instance?.fitToViewport();
    }

    protected _addMarker(lat: number, lng: number) {
        const markOptions = this.marker
            ? {
                  iconLayout: 'default#image',
                  iconImageHref: this.marker,
                  iconImageSize: [72, 96],
              }
            : {
                  preset: 'islands#redIcon',
              };

        loadScript(`https://api-maps.yandex.ru/2.1/?lang=ru_RU`)
            .then(() => {
                const { ymaps } = window as any;
                ymaps.ready(() => {
                const mark = new ymaps.Placemark([lat, lng], {}, markOptions);
                this.instance.geoObjects.add(mark);
                });
            });
    }

    protected _renderMap() {
        if (this._isFetching) {
            return html` <div class="map-loader">Загружаем карту...</div> `;
        }

        if (this._error) {
            return html` <div>${this._error.message}</div> `;
        }

        return '';
    }

    render() {
        return html` <div class="map">${this._renderMap()}</div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'x-yandex-map': YandexMap;
    }
}
