import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const inputsOfForm: NodeListOf<HTMLInputElement> = form.querySelectorAll('.js-input:required');
        const checkboxInputs: NodeListOf<HTMLInputElement> = form.querySelectorAll('input[type=checkbox]:required');
        const submitForm: HTMLElement | null = form.querySelector('.js-submit-form');
        let isSubmitting = false;
        if (submitForm) formInputsValidate(inputsOfForm, checkboxInputs, submitForm);
        const validator = createValidator(form, {
            messages: {
                en: {
                    EMPTY_FIELD: 'Must be filled',
                },
            },
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });
        const sender = createFormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
        });
        map.set(form, sender);

        form.addEventListener('submit', (event) => {
            if (isSubmitting) return;
            event.preventDefault();
            const isFormValid = validator.validate();
            if (isFormValid) {
                if (ym) ym(86942289,'reachGoal','feedbackPush');
                // if (gtag) gtag('event', 'feedbackPush');
                isSubmitting = true;

                sender.send().finally(() => {
                    isSubmitting = false;
                });
            }
        });
    });
}

function formInputsValidate(inputs: NodeListOf<HTMLInputElement>, checkboxInputs: NodeListOf<HTMLInputElement>, button: HTMLElement) {

    inputs.forEach((input) => {
        input.addEventListener('change', () => {
            if (input.value.length >= 1) {
                input.classList.remove('is-error');
            } else {
                input.classList.add('is-error');
            }
            changeCounter();
        });
    });

    checkboxInputs.forEach((input) => {
        input.addEventListener('change', () => {
            if (!input.checked) {
                input.classList.add('is-error');
            } else {
                input.classList.remove('is-error');
            }
            changeCounter();
        });
    });

    let counter = checkboxInputs.length + inputs.length;
    function changeCounter() {
        counter = checkboxInputs.length + inputs.length;
        inputs.forEach((input) => {
            if (input.value.length > 0) {
                counter--;
            }
        });

        checkboxInputs.forEach((input) => {
            if (input.checked) {
                counter--;
            }
        })
        disanleButton();
    }

    function disanleButton() {
        if (counter == 0) {
            button.classList.remove('is-disable');
        } else {
            button.classList.add('is-disable');
        }
    }
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, getInstanceByElement };

export default _module;
