import { DoubleSide, Mesh, RawShaderMaterial } from 'three';
import createGeometry from '../../libs/three-bmfont-text';
import MSDFShader from '../../libs/three-bmfont-text/shaders/msdf';

export class Text extends Mesh {
    constructor({ text, font, fontAtlas, size = 1, align, width }) {
        const geometry = createGeometry({ font, text, align, width });

        const material = new RawShaderMaterial({
            extensions: { derivatives: true },
            ...MSDFShader({
                map: fontAtlas,
                color: 0xffffff,
                side: DoubleSide,
                transparent: true,
                negate: false,
            }),
        });

        super(geometry, material);

        this.scale.set(size, size, size);
        this.rotation.x = Math.PI;
    }
}
