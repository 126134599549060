import { CountUp } from 'countup.js';
import { withLeadingZero } from '../utils/strings';

export function counterControl(target: HTMLElement, from = 0, number: number, digits = 1) {
    return new CountUp(target, number, {
        startVal: from,
        useEasing: true,
        formattingFn: (n) => withLeadingZero(n, digits),
    });
}
