import { IViewData } from '@barba/core';
import gsap from 'gsap';
import { createScene } from '../components/scene';

function onPreloaderLeave() {
    window.scrollTo(0, 0);

    if (!document.documentElement.classList.contains('no-custom-webgl')) {
        document.body.classList.add('no-scroll');
    }
}

export default {
    namespace: 'index-page',

    beforeEnter({ next }: IViewData) {
        createScene();
        const mainSection = document.querySelector<HTMLElement>('.js-main-section');
        document.addEventListener('preloader-leave', onPreloaderLeave, { once: true });

        // const panels = Array.from(next.container.querySelectorAll('.js-pin-section'));
        const mainAboutSection = next.container.querySelector('.js-main-about-section');

        // panels.forEach((panel) => {
        //     gsap.to(panel, {
        //         scrollTrigger: {
        //             trigger: panel,
        //             start: 'bottom bottom',
        //             // end: () => `+=${window.innerHeight}`,
        //             end: 'bottom bottom',
        //             pin: true,
        //             pinSpacing: false,
        //             scrub: true,
        //         },
        //     });
        // });

        if (mainSection) {
            gsap.to(mainAboutSection, {
                scrollTrigger: {
                    trigger: mainAboutSection,
                    start: 'top top',
                    end: 'bottom bottom',
                    onEnter: () => {
                        mainSection.style.opacity = '0';
                    },
                    onLeaveBack: () => {
                        mainSection.style.opacity = '';
                    },
                },
            });
        }
    },

    afterEnter() {
        onPreloaderLeave();
    },

    beforeLeave() {
        document.removeEventListener('preloader-leave', onPreloaderLeave);
    },
};
