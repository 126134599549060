import gsap from 'gsap';
import barba from '@barba/core';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { counterControl } from './counter';
import { createInitialNewsDetailPageAnimation } from '../transitions/to-article';
import { createInitialNewsPageAnimation } from '../transitions/to-news';
import { createInitialObjectsPageAnimation } from '../transitions/to-objects';
import { createInitialObjectPageAnimation } from '../transitions/to-object';
import { createInitialTeamsPageAnimation } from '../transitions/to-team';
import { createInitialVacanciesPageAnimation } from '../transitions/to-vacancies';

export const preloaderAnimationDuration = 0.7; // sec

export function timeout(ms: number): Promise<NodeJS.Timeout> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const lines = document.querySelector<HTMLElement>('.js-preloader-lines');
    const wasBodyLocked = false;
    let loaded = false;
    const state = {
        completed: false,
    };

    document.body.classList.add('no-scroll');

    function leave() {
        if (!wasBodyLocked) {
            document.body.classList.remove('no-scroll');
        }

        document.dispatchEvent(new Event('preloader-leave'));

        barba.hooks.afterEnter(() => {
            if (!loaded) {
                loaded = true;
            }
        });

        setTimeout(() => {
            gsap.to(preloader, {
                duration: 0.65,
                opacity: 0,
                onComplete: () => {
                    preloader?.classList.add('is-hidden');
                    state.completed = true;
                },
            });
        }, 100);

        const newsDetailPageAnimation = createInitialNewsDetailPageAnimation();
        const animationNewsDetail = gsap.timeline();
        animationNewsDetail.add(newsDetailPageAnimation.play(), 0);

        const newsPageAnimation = createInitialNewsPageAnimation();
        const animationNews = gsap.timeline();
        animationNews.add(newsPageAnimation.play(), 0);

        const objectsPageAnimation = createInitialObjectsPageAnimation();
        const animationObjects = gsap.timeline();
        animationObjects.add(objectsPageAnimation.play(), 0);

        const objectPageAnimation = createInitialObjectPageAnimation();
        const animationObject = gsap.timeline();
        animationObject.add(objectPageAnimation.play(), 0);

        const teamPageAnimation = createInitialTeamsPageAnimation();
        const animationTeam = gsap.timeline();
        animationTeam.add(teamPageAnimation.play(), 0);

        const vacanciesPageAnimation = createInitialVacanciesPageAnimation();
        const animationVacancies = gsap.timeline();
        animationVacancies.add(vacanciesPageAnimation.play(), 0);
    }

    function loadAsset(img: HTMLImageElement): Promise<void> {
        return new Promise((resolve) => {
            if (img.complete) {
                resolve();
            } else {
                img.onload = () => resolve();
                img.onerror = () => resolve();
            }
        });
    }

    const counterInstance = counterControl(counter!, 0, 0, 3);

    function setPercent(value: number) {
        if (counter) {
            counterInstance.update(value);
        }

        if (lines) {
            gsap.to(lines, { duration: 0.3, scaleY: value * 0.01, overwrite: true });
        }
    }

    function setP(event: any) {
        setPercent(Math.round((event.detail.loaded / event.detail.total) * 100));
    }

    const throttledSetP = throttle(setP, 500);

    function loadWebGLAssets(): Promise<void> {
        return new Promise((resolve) => {
            const debouncedOnAssetLoaded = debounce(function onAssetLoaded(event: any) {
                throttledSetP(event);

                if (event.detail.loaded === event.detail.total) {
                    document.removeEventListener('asset-loaded', onAssetLoaded);
                    resolve();
                }
            }, 100);

            if (document.querySelector('.js-canvas')) {
                document.addEventListener('asset-loaded', debouncedOnAssetLoaded);
            } else {
                setPercent(100);
                resolve();
            }
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));

        if (images.length > 0) {
            await Promise.all<any>(images.map((img) => loadAsset(img)));
        }
    }

    async function loadAssets() {
        setPercent(20);
        await Promise.all([loadAssetsFromElement(document.body), loadWebGLAssets()]);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();

// Initial load
preloader
    .loadAssets()
    .then(() => timeout(700))
    .then(() => preloader.leave());
