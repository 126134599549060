import gsap from 'gsap';

export function createInitialNewsPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-title-news-page',
        {
            x: 20,
            duration: 1,
            opacity: 0
        }
    )
    .from(
        '.a-row',
        {
            y: 20,
            duration: 0.4,
            opacity: 0
        },
        '-=0.3',
    )
    .from(
        '.a-title-news',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
        },
        '-=0.6',
    )
    .from(
        '.a-descr-news',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
        },
        '-=0.4'
    )
    .from(
        '.a-photo-news',
        {
            scale: 1.2,
            duration: 1,
            opacity: 0
        },
        '-=0.8'
    );

    return tl;
}
