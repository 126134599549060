import { ITransitionData } from '@barba/core';
import gsap from 'gsap';

export default {
    leave({ current }: ITransitionData): Promise<void> {
        return new Promise((resolve) => {
            gsap.to(current.container, {
                duration: 0.5,
                opacity: 0,
                onComplete: () => {
                    requestAnimationFrame(() => {
                        current.container.style.display = 'none';
                        resolve();
                    });
                },
            });
        });
    },

    enter({ next }: ITransitionData): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            gsap.fromTo(next.container, { opacity: 0 }, { duration: 0.5, delay: 0.4, opacity: 1, onComplete: resolve });
        });
    },
};
