import Lazyload from 'vanilla-lazyload';

export const lazyload = new Lazyload({
    elements_selector: '.lazy',
    threshold: 1000,
    use_native: true,
});

// export const videoLazyLoad = new Lazyload({
//     elements_selector: '.video-lazy',
//     threshold: 1000,
// });
