import barba from '@barba/core';

import { lazyload } from '../inits/lazy-load';
import headerTheme from '../inits/header-theme';
import reveal from '../inits/reveal';
import menu from '../inits/menu';

export function initBaseView() {
    barba.hooks.beforeEnter((data) => {
        reveal.init(data?.next.container);
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter(() => {
        setTimeout(() => {
            headerTheme.init();
        }, 1);
        menu.init();
    });

    barba.hooks.beforeLeave((data) => {
        headerTheme.destroy();
        reveal.destroy(data?.current.container);
    });

    barba.hooks.afterLeave(() => {
        document.body.classList.remove('no-scroll');
    });
}
