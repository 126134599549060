import type { InputMask } from 'imask';

const fetchImask = () =>
    Promise.all([
        import(
            'imask/esm/imask'
            /* webpackChunkName: "imask" */
        ),
        import(
            'imask/esm/masked/number'
            /* webpackChunkName: "imask-number" */
        ),
    ]).then(([m]) => m);

const map = new WeakMap<Element, InputMask<any>>();
export const thousandsSeparator = ' ';

function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const { default: IMask } = await fetchImask();
    const getMask = (_dialCode: string) => `+{${_dialCode}} (000) 000 00-00`;
    const imask = IMask(input, { mask: getMask(dialCode) });
    map.set(input, imask);
    input.classList.add('mask--initialized');
}

export async function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
        input.classList.remove('mask--initialized');
    }
}

async function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll('input[type="tel"]:not(.mask--initialized)'),
    ) as HTMLInputElement[];

    if (elements.length > 0) {
        await fetchImask();
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

export async function initDateMask(input: HTMLInputElement) {
    const { default: IMask } = await fetchImask();
    const getMask = () => `00.00.0000`;
    const imask = IMask(input, { mask: getMask() });
    map.set(input, imask);
    input.classList.add('mask--initialized');
}

async function initDateMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll('input[data-mask="date"]:not(.mask--initialized)'),
    ) as HTMLInputElement[];

    if (elements.length > 0) {
        await fetchImask();
        elements.forEach((el) => {
            initDateMask(el);
        });
    }
}

function init(container: Element | Document = document) {
    initPhoneMasks(container);
    initDateMasks(container);
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('input[data-mask]')) as HTMLInputElement[];
    elements.forEach((el) => {
        destroyPhoneMask(el);
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
