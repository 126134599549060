const observer = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                obs.unobserve(entry.target);
                entry.target?.classList.add('is-revealed');
            }
        });
    },
    { rootMargin: '-50px 0px -50px 0px' },
);

const observerSlider = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                obs.unobserve(entry.target);
                entry.target?.classList.add('is-revealed');
            }
        });
    },
    {
        rootMargin: '400px 0px 400px 0px',
        threshold: 1
    },
);

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal]'));
    elements.forEach((el) => {
        observer.observe(el);
    });

    const elementsSlider = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-slider]'));
    elementsSlider.forEach((el) => {
        observerSlider.observe(el);
    });

    const newsElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] p'));
    newsElements.forEach((el) => {
        observer.observe(el);
    });

    const newsTilteElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] h2'));
    newsTilteElements.forEach((el) => {
        observer.observe(el);
    });

    const newsListElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] ul'));
    newsListElements.forEach((el) => {
        observer.observe(el);
    });

    const newsOnListElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] ol'));
    newsOnListElements.forEach((el) => {
        observer.observe(el);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal]'));
    elements.forEach((el) => {
        observer.unobserve(el);
    });

    const elementsSlider = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-slider]'));
    elementsSlider.forEach((el) => {
        observerSlider.unobserve(el);
    });

    const newsElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] p'));
    newsElements.forEach((el) => {
        observer.unobserve(el);
    });

    const newsTilteElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] h2'));
    newsTilteElements.forEach((el) => {
        observer.unobserve(el);
    });

    const newsListElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] ul'));
    newsListElements.forEach((el) => {
        observer.unobserve(el);
    });

    const newsOnListElements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal-news] ol'));
    newsOnListElements.forEach((el) => {
        observer.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
