const header = document.querySelector<HTMLElement>('.js-header');
const commonRootMargin = '-5% 0% -94.9% 0%';
const themes = ['light', 'dark'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (header) {
            if (entry.isIntersecting) {
                const { theme } = target.dataset;

                if (!theme) {
                    themes.forEach((item) => {
                        header.classList.remove(`header-theme--${item}`);
                    });
                    return;
                }

                themes
                    .filter((item) => item !== theme)
                    .forEach((item) => {
                        header.classList.remove(`header-theme--${item}`);
                    });
                header.classList.add(`header-theme--${theme}`);
            } else if (target.classList.contains('js-news-section') && entry.intersectionRatio === 0) {
                themes.forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
                header.classList.add('header-theme--dark');
            }
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();

function createThemeObserver(rootMargin: string) {
    return new IntersectionObserver(callback, { rootMargin });
}

function init(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        const rootMargin = el.dataset.themeObserverMargin || commonRootMargin;
        const observer = createThemeObserver(rootMargin);
        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-section')).forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });

    if (header) {
        themes.forEach((item) => {
            header.classList.remove(`header-theme--${item}`);
        });
    }
}

const _module = { init, destroy };

export default _module;
