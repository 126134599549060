import barba from '@barba/core';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const header = document.querySelector('.js-header');
const menuBtn = document.querySelector<HTMLElement>('.js-menu-btn');
let btnTimeout: NodeJS.Timeout;

function onOpen() {
    header?.classList.add('header--menu-opened');
    header?.removeAttribute('inert');
    if (menuBtn) {
        btnTimeout = setTimeout(() => {
            menuBtn.removeAttribute('data-lit-popup-open');
            menuBtn.setAttribute('data-lit-popup-close', 'menu');
        }, 1);
    }
}

function onCloseComplete() {
    clearTimeout(btnTimeout);
    header?.classList.remove('header--menu-opened');

    if (menuBtn) {
        menuBtn.removeAttribute('data-lit-popup-close');
        menuBtn.setAttribute('data-lit-popup-open', 'menu');
    }
}

function init() {
    const menu = document.querySelector<LitPopupElement>('lit-popup.js-menu');

    if (menu) {
        menu.addEventListener('open', onOpen);
        menu.addEventListener('close-complete', onCloseComplete);

        barba.hooks.beforeLeave(() => {
            if (menu.instance.isOpen) {
                menu.instance.close();
            }
        });
    }
}

const _module = { init };

export default _module;
