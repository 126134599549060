import gsap from 'gsap';

export function createInitialVacanciesPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-title-vacancies',
        {
            x: 20,
            duration: 1,
            opacity: 0
        }
    ).from(
        '.a-slider-vacancies',
        {
            duration: 1,
            opacity: 0,
            y: 30,
        },
        '-=0.8'
    ).from(
        '.a-descr-vacancies',
        {
            duration: 0.6,
            opacity: 0,
        },
        '-=0.6'
    );

    return tl;
}
