import gsap from 'gsap';

export function createInitialNewsDetailPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-photo',
        {
            scale: 1.2,
            duration: 1,
            opacity: 0
        }
    )
    .from(
        '.a-date',
        {
            y: 30,
            duration: 0.4,
            opacity: 0
        },
        '-=0.3',
    )
    .from(
        '.a-title',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
        },
        '-=0.6',
    )
    .from(
        '.a-sescr',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
        },
        '-=0.4'
    )
    .from(
        '.a-social',
        {
            duration: 0.6,
            opacity: 0,
            y: 15,
        },
        '-=0.5'
    )
    .from(
        '.a-social-item',
        {
            duration: 0.4,
            opacity: 0,
            x: 15,
            stagger: 0.1
        },
        '-=0.6',
    );

    return tl;
}
