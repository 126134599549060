import {
    ACESFilmicToneMapping,
    CineonToneMapping,
    EquirectangularReflectionMapping,
    LinearToneMapping,
    NoToneMapping,
    ReinhardToneMapping,
} from 'three';
// import dat from 'three/examples/jsm/libs/dat.gui.module';
import * as dat from 'dat.gui';

export function initGUI(_module: any) {
    const gui = new dat.GUI();
    gui.domElement.parentElement.style.zIndex = '1000';
    // gui.close();

    function tweakRenderer() {
        const folder = gui.addFolder('Renderer');

        const controller = {
            toneMapping: _module.renderer.toneMapping,
            toneMappingExposure: _module.renderer.toneMappingExposure,
            // shadows: true,
        };

        const changer = () => {
            _module.setRendererProps(controller);
        };

        folder
            .add(controller, 'toneMapping', {
                No: NoToneMapping,
                Linear: LinearToneMapping,
                Reinhard: ReinhardToneMapping,
                Cineon: CineonToneMapping,
                ACESFilmic: ACESFilmicToneMapping,
            })
            .onFinishChange(changer);
        folder.add(controller, 'toneMappingExposure', 0, 3, 0.001).onChange(changer);
        // folder.add(controller, 'shadows').onChange(changer);

        folder.open();

        // changer();
    }

    tweakRenderer();

    function tweakCamera() {
        const folder = gui.addFolder('Camera');

        const controller = {
            fov: 36.9,
            posX: 48.869701076555785,
            posY: 3.7224254417992,
            posZ: 4.914885326952808,
            rotX: 0,
            rotY: 0,
            rotZ: 0,
        };

        const changer = () => {
            _module.setCameraProps(controller);
        };

        folder.add(controller, 'fov', 0, 180, 0.1).onChange(changer);
        folder.add(controller, 'posX', -300, 300, 0.0001).onChange(changer);
        folder.add(controller, 'posY', -300, 300, 0.0001).onChange(changer);
        folder.add(controller, 'posZ', -300, 300, 0.0001).onChange(changer);
        folder.add(controller, 'rotX', -1 * Math.PI, 1 * Math.PI, Math.PI / 4096).onChange(changer);
        folder.add(controller, 'rotY', -1 * Math.PI, 1 * Math.PI, Math.PI / 4096).onChange(changer);
        folder.add(controller, 'rotZ', -1 * Math.PI, 1 * Math.PI, Math.PI / 4096).onChange(changer);

        folder.open();

        // changer();
    }

    tweakCamera();

    function tweakEnvMap() {
        const folder = gui.addFolder('Scene env map');
        const envMapInput = document.createElement('input');
        envMapInput.id = 'env_map_input';
        envMapInput.type = 'file';
        envMapInput.style.display = 'none';
        document.body.appendChild(envMapInput);
        envMapInput.addEventListener('change', (event: any) => {
            const { files } = event.target;

            for (let i = 0; i < files.length; i++) {
                const f = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    // console.log(e.target!.result);
                    _module.rgbeLoader?.load(e.target!.result, (texture: any) => {
                        texture.mapping = EquirectangularReflectionMapping;
                        const hdrEquirectRenderTarget = _module.pmremGenerator.fromEquirectangular(texture);
                        _module.scene.environment = hdrEquirectRenderTarget.texture;
                    });
                };

                reader.onerror = (err) => {
                    console.error(err);
                };

                reader.readAsDataURL(f);
            }
        });

        const controller = {
            intensity: 1,
            loadFile: () => {
                envMapInput?.click();
            },
        };

        const changer = () => {
            _module.setEnvMapProps(controller);
        };

        folder.add(controller, 'loadFile').name('Load envmap');
        folder.add(controller, 'intensity', 0, 5, 0.001).onChange(changer);

        folder.open();

        // changer();
    }

    tweakEnvMap();

    // function tweakAmbientLight() {
    //     const folder = gui.addFolder('Ambient light');

    //     const controller = {
    //         intensity: 1.5,
    //     };

    //     const changer = () => {
    //         _module.setAmbientLightProps(controller);
    //     };

    //     folder.add(controller, 'intensity', 0, 5, 0.001).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakAmbientLight();

    // function tweakDirectionalLight() {
    //     const folder = gui.addFolder('Directional light');

    //     const controller = {
    //         intensity: 1.5,
    //         posX: -22.047,
    //         posY: 80,
    //         posZ: 168.862,
    //         shadowMapSize: 1024,
    //         shadowCameraNear: 102,
    //         shadowCameraFar: 291.5,
    //         shadowCameraTop: 75.282,
    //         shadowCameraRight: 115.433,
    //         shadowCameraBottom: -85.32,
    //         shadowCameraLeft: -165.621,
    //     };

    //     const changer = () => {
    //         _module.setDirectionalLightProps(controller);
    //     };

    //     folder.add(controller, 'intensity', 0, 10, 0.001).onChange(changer);
    //     folder.add(controller, 'posX', -2000, 2000, 0.001).onChange(changer);
    //     folder.add(controller, 'posY', -2000, 2000, 0.001).onChange(changer);
    //     folder.add(controller, 'posZ', -2000, 2000, 0.001).onChange(changer);
    //     folder.add(controller, 'shadowMapSize', [128, 256, 512, 1024, 2048]).onChange(changer);
    //     folder.add(controller, 'shadowCameraNear', 0.1, 1000, 0.5).onChange(changer);
    //     folder.add(controller, 'shadowCameraFar', 0.1, 1000, 0.5).onChange(changer);
    //     folder.add(controller, 'shadowCameraTop', -5000, 5000, 0.001).onChange(changer);
    //     folder.add(controller, 'shadowCameraRight', -5000, 5000, 0.001).onChange(changer);
    //     folder.add(controller, 'shadowCameraBottom', -5000, 5000, 0.001).onChange(changer);
    //     folder.add(controller, 'shadowCameraLeft', -5000, 5000, 0.001).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakDirectionalLight();

    // function tweakSky() {
    //     const folder = gui.addFolder('Sky');

    //     const controller = {
    //         elevation: 7,
    //         azimuth: -4,
    //         turbidity: 10,
    //         rayleigh: 2,
    //         mieCoefficient: 0.005,
    //         mieDirectionalG: 0.8,
    //     };

    //     const changer = () => {
    //         _module.setSkyProps(controller);
    //     };

    //     folder.add(controller, 'elevation', -1, 90, 0.01).onChange(changer);
    //     folder.add(controller, 'azimuth', -180, 180, 0.1).onChange(changer);
    //     folder.add(controller, 'turbidity', 0, 15, 0.001).onChange(changer);
    //     folder.add(controller, 'rayleigh', 0, 15, 0.001).onChange(changer);
    //     folder.add(controller, 'mieCoefficient', 0, 1, 0.00001).onChange(changer);
    //     folder.add(controller, 'mieDirectionalG', 0, 3, 0.00001).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakSky();

    // function tweakWater() {
    //     const folder = gui.addFolder('Water');

    //     const controller = {
    //         enabled: true,
    //         distortionScale: 3.7,
    //         color: 0x001e0f,
    //     };

    //     const changer = () => {
    //         _module.setWaterProps(controller);
    //     };

    //     folder.add(controller, 'enabled').onChange(changer);
    //     folder.add(controller, 'distortionScale', 0, 8, 0.1).onChange(changer);
    //     folder.addColor(controller, 'color').onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakWater();

    // function tweakDof() {
    //     const folder = gui.addFolder('Depth of field');

    //     const controller = {
    //         focus: 10,
    //         aperture: 1,
    //         maxblur: 0.002,
    //     };

    //     const changer = () => {
    //         _module.setDofProps?.(controller);
    //     };

    //     folder.add(controller, 'focus', 10.0, 3000.0, 10).onChange(changer);
    //     folder.add(controller, 'aperture', 0, 10, 0.1).onChange(changer);
    //     folder.add(controller, 'maxblur', 0.0, 0.01, 0.001).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakDof();

    // function tweakTonemapping() {
    //     const folder = gui.addFolder('Adaptive tonemapping');

    //     const controller = {
    //         enabled: false,
    //         middleGrey: 1.5,
    //         maxLuminance: 16,
    //         adaptionRate: 2.0,
    //     };

    //     const changer = () => {
    //         _module.setTonemappingProps(controller);
    //     };

    //     folder.add(controller, 'enabled').onChange(changer);
    //     folder.add(controller, 'middleGrey', 0, 12).onChange(changer);
    //     folder.add(controller, 'maxLuminance', 1, 30).onChange(changer);
    //     folder.add(controller, 'adaptionRate', 0, 10).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakTonemapping();

    // function tweakSSR() {
    //     const folder = gui.addFolder('SSR');

    //     const controller = {
    //         enabled: true,
    //     };

    //     const changer = () => {
    //         _module.setSSRProps?.(controller);
    //     };

    //     folder.add(controller, 'enabled').onChange(changer);
    //     folder.add(controller, 'middleGrey', 0, 12).onChange(changer);

    //     folder.open();

    //     changer();
    // }

    // tweakSSR();

    function tweakSSAO() {
        const folder = gui.addFolder('SSAO');

        const controller = {
            enabled: _module.postprocessing.ssao.enabled,
            kernelRadius: _module.postprocessing.ssao.kernelRadius,
            minDistance: _module.postprocessing.ssao.minDistance,
            maxDistance: _module.postprocessing.ssao.maxDistance,
        };

        const changer = () => {
            _module.setSSAOProps?.(controller);
        };

        folder.add(controller, 'enabled').onChange(changer);
        folder.add(controller, 'kernelRadius').min(0).max(2).step(0.001).onChange(changer);
        folder.add(controller, 'minDistance').min(0.001).max(0.5).step(0.001).onChange(changer);
        folder.add(controller, 'maxDistance').min(0.002).max(1).step(0.001).onChange(changer);

        folder.open();

        // changer();
    }

    if (_module.postprocessing?.ssao) {
        tweakSSAO();
    }

    function tweakFog() {
        const folder = gui.addFolder('Fog');

        const controller = {
            enabled: true,
            near: _module.scene.fog.near,
            far: _module.scene.fog.far,
            color: 0xb6b5b7,
        };

        const changer = () => {
            _module.setFogProps(controller);
        };

        folder.add(controller, 'enabled').onChange(changer);
        folder.add(controller, 'near', 0, 1000, 0.01).onChange(changer);
        folder.add(controller, 'far', 0, 1000, 0.01).onChange(changer);
        folder.addColor(controller, 'color').onChange(changer);

        folder.open();

        // changer();
    }

    if (_module.scene.fog) {
        tweakFog();
    }
}
