import './CookiesAgreement/CookiesAgreement';
import './Collapse/Collapse';
import './Tabs/Tabs';
import './LitPopupElement/LitPopupElement';
import './Upload/Upload';
import { YandexMap } from './YandexMap/yandex-map';

export default function registerCustomElements() {
    window.customElements.define('y-yandex-map', YandexMap);
}
