import { Group, Mesh, DoubleSide, CylinderBufferGeometry, ShaderMaterial, Vector3 } from 'three';
import { Text } from './Text';
import vertexShader from './shaders/Mark/vertex.glsl';
import fragmentShader from './shaders/Mark/fragment.glsl';

const baseLineMaterial = new ShaderMaterial({
    uniforms: {
        uColor: { value: new Vector3(1, 1, 1) },
    },
    side: DoubleSide,
    transparent: true,
    vertexShader,
    fragmentShader,
});

export class Mark extends Group {
    constructor({
        font,
        fontAtlas,
        text,
        position,
    }: {
        font: any;
        fontAtlas: any;
        text: string;
        position: [number, number, number];
    }) {
        super();

        const textMesh = new Text({ text, font, fontAtlas, size: 0.03 });
        textMesh.name = 'Mark_text';
        textMesh.position.x = 0.5;
        textMesh.scale.y *= -1;
        this.add(textMesh);

        const line = new Mesh(new CylinderBufferGeometry(0.05, 0.05, position[1], 16), baseLineMaterial);
        line.position.y = -position[1] / 2 + 1;
        this.add(line);
    }
}
