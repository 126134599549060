import type { Mesh, Object3D, Material } from 'three';

export function disposeMesh(mesh: Mesh) {
    mesh.geometry?.dispose();
    if (mesh.material) {
        if (mesh.material instanceof Array) {
            for (let i = 0; i < mesh.material.length; ++i) {
                mesh.material[i].dispose();
            }
        } else {
            mesh.material.dispose();
        }
    }
}

export function getMaterialByName(obj: Object3D, name: string): Material | null {
    let pickedMaterial: Material | null;

    obj.traverse((child) => {
        if ((child as any).material && (child as any).material.name === name) {
            pickedMaterial = (child as any).material;
        }
    });

    return pickedMaterial;
}
