import {
    FloatType,
    HalfFloatType,
    LinearFilter,
    PlaneBufferGeometry,
    RGBAFormat,
    RGBFormat,
    Vector2,
    WebGLMultisampleRenderTarget,
    WebGLRenderTarget,
} from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
// import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass';
import { SSRPass } from 'three/examples/jsm/postprocessing/SSRPass';
import { ReflectorForSSRPass } from 'three/examples/jsm/objects/ReflectorForSSRPass';
import type { Canvas } from './offscreen-module';

export function initPostprocessing(_module: Canvas) {
    _module.postprocessing = {};

    const rtParams = {
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBFormat,
        stencilBuffer: false,
        type: HalfFloatType,
        frameBufferType: HalfFloatType,
    };

    const size = _module.renderer.getDrawingBufferSize(new Vector2());

    // const rt = _module.renderer.capabilities.isWebGL2
    //     ? new WebGLMultisampleRenderTarget(size.width, size.height, rtParams)
    //     : new WebGLRenderTarget(_module.state.width, _module.state.height, rtParams);

    // _module.composer = new EffectComposer(_module.renderer, rt);
    _module.composer = new EffectComposer(_module.renderer);

    const renderPass = new RenderPass(_module.scene, _module.camera);
    _module.composer.addPass(renderPass);

    // if (!_module.renderer.capabilities.isWebGL2) {
    //     const fxaaPass = new ShaderPass(FXAAShader);
    //     _module.postprocessing.fxaa = fxaaPass;
    //     fxaaPass.material.uniforms.resolution.value.x = 1 / (_module.state.width * _module.state.dpr);
    //     fxaaPass.material.uniforms.resolution.value.y = 1 / (_module.state.height * _module.state.dpr);
    //     _module.composer.addPass(fxaaPass);
    // }
    const fxaaPass = new ShaderPass(FXAAShader);
    _module.postprocessing.fxaa = fxaaPass;
    fxaaPass.material.uniforms.resolution.value.x = 1 / (_module.state.width * _module.state.dpr);
    fxaaPass.material.uniforms.resolution.value.y = 1 / (_module.state.height * _module.state.dpr);
    _module.composer.addPass(fxaaPass);

    if (
        !(_module.options.gpuTier.gpu === 'apple gpu (Apple GPU)') ||
        (_module.options.gpuTier.fps ? _module.options.gpuTier.fps > 30 : _module.options.gpuTier.tier > 1)
    ) {
        // const bokehPass = new BokehPass(_module.scene, _module.camera, {
        //     focus: 10,
        //     aperture: 1 * 0.00001,
        //     maxblur: 0.002,
        //     width: _module.state.width,
        //     height: _module.state.height,
        // });
        // _module.postprocessing.bokeh = bokehPass;
        // _module.composer.addPass(bokehPass);

        // _module.setDofProps = function setDofProps({ focus, aperture, maxblur }: Record<string, number>) {
        //     if (this.postprocessing?.bokeh) {
        //         this.postprocessing.bokeh.uniforms.focus.value = focus;
        //         this.postprocessing.bokeh.uniforms.aperture.value = aperture * 0.00001;
        //         this.postprocessing.bokeh.uniforms.maxblur.value = maxblur;
        //     }
        // };

        // const resolution = {
        //     width: _module.state.width,
        //     height: _module.state.height,
        // };
        // const groundReflector = new ReflectorForSSRPass(new PlaneBufferGeometry(1, 1), {
        //     clipBias: 0.0003,
        //     textureWidth: _module.state.width,
        //     textureHeight: _module.state.height,
        //     color: 0x888888,
        //     useDepthTexture: true,
        // });
        // groundReflector.name = 'Ground reflector';
        // groundReflector.material.depthWrite = false;
        // groundReflector.rotation.x = -Math.PI / 2;
        // groundReflector.visible = false;
        // _module.scene.add(groundReflector);

        // const selects: any[] = [];

        // _module.scene.traverse((obj) => {
        //     if (obj.isMesh && ['GROUND_2'].includes(obj.name)) {
        //         selects.push(obj);
        //     }
        // });

        // const ssrPass = new SSRPass({
        //     renderer: _module.renderer,
        //     scene: _module.scene,
        //     camera: _module.camera,
        //     width: resolution.width,
        //     height: resolution.height,
        //     groundReflector,
        //     selects,
        // });
        // ssrPass.blur = false;
        // ssrPass.thickness = 0.018;
        // ssrPass.infiniteThick = false;
        // ssrPass.maxDistance = 10;
        // groundReflector.maxDistance = ssrPass.maxDistance;

        // _module.composer.addPass(ssrPass);

        const ssaoPass = new SSAOPass(
            _module.scene,
            _module.camera,
            _module.state.width * _module.state.dpr,
            _module.state.height * _module.state.dpr,
        );
        _module.postprocessing.ssao = ssaoPass;
        ssaoPass.kernelRadius = 0.4;
        ssaoPass.minDistance = 0.001;
        ssaoPass.maxDistance = 0.4;
        _module.composer.addPass(ssaoPass);
    }

    const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
    _module.composer.addPass(gammaCorrectionPass);

    // const fxaaPass = new ShaderPass(FXAAShader);
    // _module.postprocessing.fxaa = fxaaPass;
    // fxaaPass.material.uniforms.resolution.value.x = 1 / (_module.state.width * _module.state.dpr);
    // fxaaPass.material.uniforms.resolution.value.y = 1 / (_module.state.height * _module.state.dpr);
    // _module.composer.addPass(fxaaPass);
}
