import './webpack-imports';
import './sentry';
import { applyPolyfills } from './polyfills';
import './custom-elements';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { CustomEase } from 'gsap/CustomEase';
import barba from '@barba/core';
import Axios from 'axios';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import vhMobileFix from '../modules/vh-mobile-fix';
import BaseTransition from './transitions/base';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import IndexPageView from './views/index';
import registerCustomElements from './custom-elements/index';
// import yandexMap from './custom-elements/YandexMap/yandex-map';
import ajaxForm from './inits/ajax-form';
import inputMasks from './inits/masks';
import './components/preloader';

document.documentElement.classList.add('js-ready');
Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase);

    vhMobileFix();
    calculateScrollbarWidth();
    initBaseView();
    registerCustomElements();

    ajaxForm.init();
    inputMasks.init();

    barba.init({
        transitions: [BaseTransition],
        views: [
            IndexPageView,
            lazyView('docs-page', () =>
                import(/* webpackChunkName: "docs-page" */ './views/docs').then((m) => m.default),
            ),
            lazyView('news-page', () =>
                import(/* webpackChunkName: "news-page" */ './views/news').then((m) => m.default),
            ),
            lazyView('object-page', () =>
                import(/* webpackChunkName: "object-page" */ './views/object').then((m) => m.default),
            ),
            lazyView('news-detail-page', () =>
                import(/* webpackChunkName: "news-detail-page" */ './views/news-detail').then((m) => m.default),
            ),
            lazyView('objects-page', () =>
                import(/* webpackChunkName: "objects-page" */ './views/objects').then((m) => m.default),
            ),
            lazyView('team-page', () =>
                import(/* webpackChunkName: "team-page" */ './views/team').then((m) => m.default),
            ),
            lazyView('search-page', () =>
                import(/* webpackChunkName: "search-page" */ './views/search').then((m) => m.default),
            ),
            lazyView('vacancies-page', () =>
                import(/* webpackChunkName: "search-page" */ './views/vacancies').then((m) => m.default),
            ),
        ],
        prefetchIgnore: true,
        preventRunning: true,
        prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
        timeout: 5000,
        // requestError: (trigger, action, url, response) => {
        //     // go to a custom 404 page if the user click on a link that return a 404 response status
        //     // const prevUrl = window.history.state.states[window.history.state.states.length - 2]?.url;
        //     if (action === 'click' && response.status === 404) {
        //         barba.go(`${ROOT_PATH}404/`);
        //         // if (prevUrl) {
        //         //     window.addEventListener('popstate', (event) => {
        //         //         event.preventDefault();
        //         //         barba.go(prevUrl);
        //         //     });
        //         // }
        //     }

        //     // prevent Barba from redirecting the user to the requested URL
        //     // this is equivalent to e.preventDefault()
        //     return false;
        // },
        debug: NODE_ENV === 'development',
    });

    const searchParams = new URLSearchParams(window.location.search.replace('?', ''));

    if (searchParams.get('hidden') === 'true') {
        document.documentElement.classList.add('no-ui');

        document.addEventListener('preloader-leave', () => {
            const proxy = { progress: 0 };
            const height = document.documentElement.scrollHeight;
            document.querySelector('.js-canvas').style.pointerEvents = 'none';

            gsap.to(proxy, {
                duration: 25,
                delay: 5,
                progress: 1,
                ease: 'power1.inOut',
                onUpdate: () => {
                    if (window.st) {
                        window.st.scroll(proxy.progress * height);
                    }
                },
            });
        });
    }
});

// module.hot?.accept();
