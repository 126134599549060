import gsap from 'gsap';

export function createInitialTeamsPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-title-object',
        {
            x: 30,
            duration: 1,
            opacity: 0
        }
    )
    .from(
        '.a-content-object',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
        },
        '-=0.4'
    );

    return tl;
}
