import { createOffscreenCanvas } from './createOffscreenCanvas';
import { getGPUTier } from 'detect-gpu';
import Stats from 'three/examples/jsm/libs/stats.module';
import debounce from 'lodash.debounce';
import gsap from 'gsap';
import throttle from 'lodash.throttle';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { initGUI } from './gui';
import { createInitialMainPageTimeline } from '../../animations/initial-main-page';
import { baseEasing } from '../../animations/easings';
import { counterControl } from '../counter';
import { preloader } from '../preloader';
import { LitPopupElement } from '../../custom-elements/LitPopupElement/LitPopupElement';

const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
const DEBUG = /* NODE_ENV === 'development' &&  */ searchParams.get('debug') === 'true';

export function createScene() {
    const canvasContainer = document.querySelector<HTMLElement>('.js-canvas-container');
    const canvas = document.querySelector<HTMLCanvasElement>('.js-canvas');

    if (!canvas) {
        throw new Error('No canvas element found.');
    }

    let rAF: number;
    let stats: Stats | null;
    let offscreen: any;
    let rect = canvas.parentElement!.getBoundingClientRect();
    const shouldAnimate = true;
    const mainSection = document.querySelector('.js-main-section');
    const slides = Array.from(document.querySelectorAll<HTMLElement>('.js-main-slide'));
    const labels = document.querySelector('.js-room-labels');
    const links = Array.from(document.querySelectorAll<HTMLElement>('.js-map-object'));
    const progress = { value: 0, prevValue: 0, beforeResizeValue: 0 };
    let st: ScrollTrigger | null;
    const mainPageTl = createInitialMainPageTimeline();

    function onClick() {
        offscreen?.click((meshName?: string) => {
            if (meshName) {
                const exactLink = links.find((link) => link.dataset.object === meshName);
                exactLink?.click();
            }
        });
    }

    async function onResize() {
        rect = canvas!.parentElement!.getBoundingClientRect();

        if (offscreen) {
            offscreen.onResize({ width: rect.width, height: rect.height });
        }
    }

    const debouncedOnResize = debounce(onResize, 100);

    function onPointerMove(event: MouseEvent) {
        if (offscreen && rect) {
            offscreen.setPointer((event.clientX / rect.width) * 2 - 1, -(event.clientY / rect.height) * 2 + 1);
        }
    }

    const throttledOnPointerMove = throttle(onPointerMove, 100);

    function render() {
        stats?.update();
    }

    function animate() {
        if (shouldAnimate) {
            render();
        }

        rAF = requestAnimationFrame(animate);
    }

    async function destroy() {
        cancelAnimationFrame(rAF);

        if (st) {
            st.kill();
            st = null;
        }

        if (offscreen) {
            offscreen.destroy();
        }

        window.removeEventListener('resize', debouncedOnResize);

        if (canvas) {
            canvas.removeEventListener('click', onClick);
            canvas.removeEventListener('mousemove', throttledOnPointerMove);
        }
    }

    function showInitialText(delay = 0) {
        gsap.to('.js-main-title-sm', {
            duration: 0.7,
            yPercent: 0,
            overwrite: true,
            ease: baseEasing,
            delay,
        });
        gsap.to('.js-main-title-lg', {
            duration: 0.7,
            yPercent: 0,
            overwrite: true,
            ease: baseEasing,
            delay,
        });
    }

    getGPUTier().then((gpuTier) => {
        if (typeof gpuTier.fps !== 'undefined' && gpuTier.fps > 0 && gpuTier.fps <= 20) {
            // fallback
            document.documentElement.classList.add('no-custom-webgl');
            const video = document.createElement('video');
            video.muted = true;
            video.loop = true;
            video.autoplay = true;
            video.playsInline = true;
            video.classList.add('fallback-video');
            video.src = `${PUBLIC_PATH}video/rsi-fallback.mp4`;
            canvas.style.display = 'none';
            canvasContainer?.appendChild(video);

            if (preloader.state.completed) {
                showInitialText(1);
            }

            document.addEventListener(
                'preloader-leave',
                () => {
                    showInitialText(1);
                },
                { once: true },
            );
            document.dispatchEvent(new CustomEvent('asset-loaded', { detail: { loaded: 1, total: 1 } }));
        } else {
            offscreen = createOffscreenCanvas({
                canvas,
                dpr: Math.min(window.devicePixelRatio, 1.5),
                width: window.innerWidth,
                height: window.innerHeight,
                gpuTier,
                debug: DEBUG,
            });

            offscreen.load((data: { loaded: number; total: number }) => {
                document.dispatchEvent(new CustomEvent('asset-loaded', { detail: data }));
            });

            offscreen.waitForInitialAnimationSignal(() => {
                mainPageTl.play();
            });

            const counters = slides.map((slide) => {
                const counterEl = slide.querySelector<HTMLElement>('.js-main-slide-count');

                if (counterEl) {
                    const { number } = counterEl.dataset;
                    const counter = counterControl(counterEl, 0, Number(number));
                    counter.paused = true;
                    return counter;
                }

                return undefined;
            });

            offscreen.waitForStart(() => {
                const scrollDownEl = document.querySelector('.js-scroll-down');
                const infoPopup = document.querySelector<LitPopupElement>('[data-lit-popup="info"]');

                document.body.classList.remove('no-scroll');
                canvas.addEventListener('mousemove', throttledOnPointerMove);

                function showSlideContent(index: number) {
                    slides[index].classList.add('is-visible');
                    const counterInstance = counters[index];

                    if (counterInstance) {
                        counterInstance.reset();
                        counterInstance.start();
                    }
                }

                function hideSlideContent(index: number) {
                    slides[index].classList.remove('is-visible');
                }

                function setMapView() {
                    mainSection?.classList.add('is-map-view');
                    labels?.classList.add('is-visible');
                    infoPopup?.open();

                    if (offscreen) {
                        if (searchParams.get('hidden') !== 'true') {
                            offscreen.setState({ allowRaycasting: true, cameraMovementMult: 4 });
                        }

                        offscreen.showMarks();
                    }
                }

                function removeMapView() {
                    mainSection?.classList.remove('is-map-view');
                    labels?.classList.remove('is-visible');
                    offscreen.setState({ allowRaycasting: false, cameraMovementMult: 2 });
                    offscreen.hideMarks();
                    offscreen.resetHover();
                }

                function showSlide(index: number, from: number, to: number) {
                    if (progress.value >= from && progress.value <= to) {
                        if (progress.prevValue < from || progress.prevValue > to) {
                            showSlideContent(index);
                        }
                    } else if (progress.prevValue >= from || progress.prevValue <= to) {
                        hideSlideContent(index);
                    }
                }

                st = ScrollTrigger.create({
                    trigger: mainSection,
                    start: () => 0,
                    end: () => `+=${window.innerHeight * 17}`,
                    scrub: true,
                    invalidateOnRefresh: true,
                    onUpdate: (self) => {
                        progress.value = self.progress;
                        offscreen.setScrollProgress(progress.value);

                        if (progress.value >= 0.08) {
                            if (progress.prevValue < 0.08) {
                                scrollDownEl?.classList.add('is-hidden');
                                gsap.to('.js-main-title-sm', {
                                    duration: 1,
                                    yPercent: -105,
                                    overwrite: true,
                                    ease: baseEasing,
                                });
                                gsap.to('.js-main-title-lg', {
                                    duration: 1,
                                    yPercent: -105,
                                    overwrite: true,
                                    ease: baseEasing,
                                });
                            }
                        } else if (progress.prevValue >= 0.08) {
                            scrollDownEl?.classList.remove('is-hidden');
                            showInitialText();
                        }

                        if (slides[0]) {
                            showSlide(0, 0.24, 0.36);
                        }

                        if (slides[1]) {
                            showSlide(1, 0.47, 0.59);
                        }

                        if (slides[2]) {
                            showSlide(2, 0.67, 0.77);
                        }

                        if (progress.value >= 0.95 && progress.value <= 1) {
                            if (progress.prevValue < 0.95 || progress.prevValue > 1) {
                                setMapView();
                            }
                        } else if (progress.prevValue >= 0.95 && progress.prevValue <= 1) {
                            removeMapView();
                        }

                        progress.prevValue = progress.value;
                    },
                });
                window.st = st;

                canvas.addEventListener('click', onClick);

                if (DEBUG) {
                    stats = Stats();
                    document.body.appendChild(stats.domElement);
                    initGUI(offscreen);
                    document.documentElement.classList.remove('no-system-cursor');
                }
            });

            animate();
        }
    });

    window.addEventListener('resize', debouncedOnResize);

    return { destroy };
}
