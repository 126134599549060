import gsap from 'gsap';

export function createInitialObjectPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-title-object-page',
        {
            x: 30,
            duration: 1,
            opacity: 0
        }
    )
    .from(
        '.a-descr-line-object',
        {
            duration: 0.6,
            opacity: 0,
            x: 30,
            stagger: 0.2
        },
        '-=0.6',
    )
    .from(
        '.a-photo-object',
        {
            duration: 0.6,
            opacity: 0,
            scale: 1.2,
        },
        '-=0.4'
    );

    return tl;
}
