import gsap from 'gsap';

export function createInitialObjectsPageAnimation() {

    const tl = gsap.timeline();

    tl.from(
        '.a-title-objects-page',
        {
            x: 30,
            duration: 1,
            opacity: 0
        }
    )
    .from(
        '.a-filter-row',
        {
            duration: 0.4,
            opacity: 0
        },
        '-=0.6',
    )
    .from(
        '.a-filter-item',
        {
            duration: 0.6,
            opacity: 0,
            x: 20,
            stagger: 0.2
        },
        '-=0.6',
    )
    .from(
        '.a-descr-object',
        {
            duration: 0.6,
            opacity: 0,
            y: 30,
            stagger: 0.3
        },
        '-=0.4'
    );

    return tl;
}
